* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.navbar-light .navbar-nav .nav-link,
.list-group-item a {
  color: #aaa;
}
.navbar {
  padding: 30px;
}
a {
  text-decoration: none;
}

a:hover,
a:active {
  color: #fafafa !important;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover {
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
}
body {
  background: #797979;
  color: #aaa;
  font-size: 1.25rem;
}

.fixed-top {
  background-color: #161d2f;
}
.home {
  display: flex;
  flex-direction: column;
  font-size: 4.5rem;
  justify-content: center;
  height: 100vh;
  color: #fff;
  padding: 0px 10%;
}
.home .name {
  font-size: 6.5rem;
  margin-bottom: 30px;
}
.home font,
.project font {
  color: #0095ff;
}
.projects,
.services,
.about,
.contact {
  margin-top: 100px;
  text-align: center;
  color: #fff;
}
.about {
  font-size: 1.5rem;
  width: 58%;
  margin: 100px auto;
  text-align: center;
}
.contact {
  width: 100%;
  text-align: left;
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10%;
}
.contact h2 {
  text-align: center;
  margin-bottom: 30px;
}

.contact-data {
  border-left: 1px solid #dfdfdf;
  padding-left: 40px;
  display: block;
}
.content {
  display: flex;
  align-items: center;
}
.content p {
  text-align: justify;
}

.projects .wrapper,
.services .wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.card {
  background-color: transparent;
  border: 1px solid #0095ff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.card link {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
}

.card title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.card-text{
  color: #0094fd;
}

.services {
  font-size: 1.5rem;
  width: 58%;
  margin: 100px auto;
  text-align: center;
}
.about {
  height: 93vh;
  margin: 100px auto;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.contact {
  height: 93vh;
}
.progress-bar-animate {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #0095ff;
  transform-origin: 0%;
  z-index: 1050;
}
@media (max-width: 1000px) {
  .home {
    font-size: 3.5rem;
  }
  .home .name {
    font-size: 5.5rem;
    margin-bottom: 30px;
  }
}

@media (max-width: 800px) {
  .contact-data {
    border-left: none;
    margin: 30px 0px;
    padding: 40px 0px 0px 0px;
    border-top: 1px solid #0095ff;
  }
  .home {
    font-size: 2.5rem;
  }
  .home .name {
    font-size: 4.5rem;
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  .about {
    margin: 100px auto;
    width: 90%;
  }
  .home {
    font-size: 2.5rem;
  }
  .home .name {
    font-size: 3.5rem;
    margin-bottom: 30px;
  }
}

.list-group-item a {
  color: #000;
}

.list-group-item:hover {
  background-color: #021a31;
  border: 1px solid #fafafa;
}
.list-group-item a:hover,
.list-group-item:hover a {
  color: #fafafa;
}
.navbar-toggler {
  background-color: #ffffff;
}