body {
    background: #797979;
    color: #ffffff;
    font-size: 1.25rem;
    margin: 4rem;
    text-align: center;
}

.about {
    padding: 2rem;
    text-align: center;
}

.about h2 {
    font-size: 2rem;
    margin: 1rem;
}

.about p {
    font-size: 1.25rem;
    margin: 1rem;
}