body {
    background: #797979;
    color: #ffffff;
    font-size: 1.25rem;
    margin: 4rem;
    text-align: center;
}

.projects {
    font-size: 1.25rem;
    margin: 4rem;
    padding: 4rem;
    text-align: center;
}

h2 {
    font-size: 2rem;
    margin: 1rem;
    padding: 1rem;
}

p {
    font-size: 1.25rem;
    margin: 1rem;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

